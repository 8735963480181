<template>
  <div class="device-repair-wrapper">
    <div class="header-box">
      <div class="icon">
        <img :src="userIcon" alt="userIcon" />
      </div>
      <div class="text">
        <h3>{{ detailData.commitUserName }}</h3>
        <span>{{ detailData.approvalFlag ? '已审批' : '等待审批' }}</span>
      </div>
    </div>
    <div class="basic-box">
      <ul>
        <li>
          <span>维修对象：</span><span>{{ detailData.deviceName }}</span>
        </li>
        <li>
          <span>设备编号：</span><span>{{ detailData.deviceNo }}</span>
        </li>
        <li>
          <span>故障等级：</span><span>{{ detailData.faultLevelName }}</span>
        </li>
        <li>
          <span>故障描述：</span><span>{{ detailData.faultReason }}</span>
        </li>
      </ul>
      <div class="detail" @click="handleGoCheckDetail">
        <span>查看详情</span>
        <i
          class="iconfont icontiaozhuanqianwangyoujiantouxiangyouxiayibuxianxing"
        ></i>
      </div>
    </div>
    <div class="step-box">
      <van-steps
        direction="vertical"
        :active="
          detailData.faultStatus > -1 && detailData.faultStatus <= 2
            ? 0
            : detailData.faultStatus == 3
            ? 1
            : detailData.faultStatus > 3
            ? 2
            : -1
        "
      >
        <van-step>
          <div class="title">创建工单</div>
          <div class="time">{{ detailData.commitTime }}</div>
          <div class="status">
            {{ detailData.commitUserName }}创建了故障工单
          </div>
        </van-step>
        <van-step>
          <div class="title">提交工单</div>
          <div class="time">{{ detailData.finishTime }}</div>
          <div class="status">
            {{ detailData.realRepairUserName }}提交了故障工单
          </div>
        </van-step>
        <van-step>
          <div class="title">
            {{ detailData.faultStatus > 3 ? '已审批' : '等待审批' }}
          </div>
          <div class="time" v-if="detailData.faultStatus > 3">
            {{ detailData.approvalTime }}
          </div>
          <div class="status" v-if="detailData.faultStatus > 3">
            {{ detailData.faultStatus === 4 ? '同意' : '拒绝 ' }}
          </div>
          <!-- <div
            class="status"
            v-if="detailData.faultStatus == 3 && (detailData.approvalType == 1 ||detailData.approvalType == 2)"
          >
            等待{{ detailData.approvalUserNames }}其中一个人的审批
          </div> -->
        </van-step>
      </van-steps>
    </div>
    <div class="tab-bar-box" v-if="detailData.faultStatus == 3">
      <span class="left" @click="postApprovalFault(5)">拒绝</span>
      <span class="right" @click="postApprovalFault(4)">同意</span>
    </div>
    <Dialog
      :show="show"
      :info="dialogInfo"
      @handleCloseDialogClick="handleCloseDialogClick"
      @handleQueryDialogClick="handleQueryDialogClick"
    />
    <Dialog1
      :show="show1"
      :info="dialogInfo1"
      @handleQueryDialogClick="handleQueryDialogClick1"
    />
  </div>
</template>

<script>
import Vue from 'vue'
import Dialog1 from './components/Dialog'
import Dialog from '@/components/Dialog'
import { Step, Steps, Toast, Icon } from 'vant'
import { postApprovalFaultDetail, postApprovalFault } from '@/api/approval'
import userIcon from '@/assets/img/my/user-icon.png'
import { getPreCheckStatus } from '@/api/check'
Vue.use(Step)
Vue.use(Steps)
Vue.use(Toast)
Vue.use(Icon)
export default {
  name: 'ApprovalDetail',
  components: {
    Dialog1,
    Dialog,
  },
  data() {
    return {
      userIcon,
      detailData: {},
      currentTaskId: 0,
      dialogInfo: {
        question: '执行过程中其他人无法进入该点检任务，是否开始点检？',
        cancerTxt: '取消',
        queryTxt: '确定',
      },
      dialogInfo1: {
        question: '当前张三正在执行点检任务，您无法进入',
      },
      show1: false,
      show: false,
    }
  },
  mounted() {
    this.postApprovalFaultDetail()
  },
  methods: {
    /**
     * 进入点检任务线路前确认接口
     */
    getPreCheckStatus(id) {
      this.currentTaskId = id
      getPreCheckStatus(id).then((res) => {
        if (res.retCode === 0) {
          //异常
          this.show1 = true
          this.dialogInfo1.question = res.retMsg
        }
        if (res.retCode === 1) {
          //正常
          this.show = true
          this.dialogInfo.question = res.retMsg
        }
      })
    },
    /**
     * 点检详情
     */
    handleGoCheckDetail() {
      const orderId = this.$route.query.orderId
      // this.getPreCheckStatus(taskId)
      this.$router.push({
        path: '/deviceRepair/status',
        query: {
          id: orderId,
          show: true,
        },
      })
    },
    handleCloseDialogClick() {
      this.show = false
    },
    handleQueryDialogClick() {
      this.$router.push({
        path: '/taskList',
        query: {
          taskId: this.currentTaskId,
        },
      })
      this.show = false
    },
    /**
     * 弹窗确认（当前张三正在执行点检任务，您无法进入）
     */
    handleQueryDialogClick1() {
      this.show1 = false
    },
    /**
     * 获取详情
     */
    postApprovalFaultDetail() {
      const orderId = this.$route.query.orderId
      postApprovalFaultDetail(orderId).then((res) => {
        this.detailData = res.details
      })
    },
    /**
     * 审批
     */
    postApprovalFault(approvalFlag) {
      const orderId = this.$route.query.orderId
      postApprovalFault(orderId, approvalFlag).then((res) => {
        if (res.retCode === 1) {
          Toast.success(res.retMsg)
          setTimeout(() => {
            window.location.reload()
          }, 1000)
        } else {
          Toast.fail(res.retMsg)
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import '../../style/mixin';
.device-repair-wrapper {
  width: 100%;
  min-height: 100%;
  box-sizing: border-box;
  background-color: #f1f1f1;
  /deep/.van-overlay {
    z-index: 7;
  }
  .header-box {
    @include wh(100%, 3.6rem);
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 0 1rem;
    .icon {
      @include wh(2.4rem, 2.4rem);
      border-radius: 50%;
      margin-right: 0.5rem;
      img {
        @include wh(100%, 100%);
      }
    }
    .text {
      h3 {
        @include sc(0.75rem, #333);
      }
      span {
        @include sc(0.7rem, #ffa141);
      }
    }
  }
  .basic-box {
    background-color: white;
    box-sizing: border-box;
    padding: 0.5rem 1rem;
    ul {
      li {
        @include sc(0.7rem, #666666);
        line-height: 1.7rem;
        display: flex;
        flex-direction: row;

        span:nth-child(1) {
          font-weight: bold;
        }
        span:nth-child(2) {
          display: block;
          width: 0;
          flex: 1;
          color: #666;
        }
      }
    }
    .detail {
      display: flex;
      justify-content: space-between;
      align-items: center;
      span {
        width: 0;
        flex: 1;
        @include sc(0.6rem, #4a8cff);
        font-weight: bold;
        margin-right: 0.5rem;
      }
      i {
        font-size: 0.8rem;
      }
    }
  }
  .step-box {
    margin-top: 1rem;
    /deep/.van-hairline {
      &::after {
        border: 0 !important;
      }
      .van-step__title {
        .title {
          font-size: 0.6rem;
          font-weight: bold;
        }
      }
    }
    /deep/.van-step__icon {
      font-size: 0.8rem;
    }
    /deep/.van-step__circle {
      @include wh(0.7rem, 0.7rem);
      // background-color: #cccccc;
    }
    .title {
      @include sc(0.7rem, #4a8cff);
      margin-bottom: 0.7rem;
    }
    .time {
      @include sc(0.6rem, #999999);
      // margin: 0.7rem 0;
      margin-bottom: 0.7rem;
    }
    .status {
      @include sc(0.7rem, #666666);
      margin-bottom: 0.5rem;
    }
  }
  .tab-bar-box {
    @include wh(100%, 2.3rem);
    background-color: white;
    position: fixed;
    left: 0;
    bottom: 0;
    box-sizing: border-box;
    border-top: 1px solid #e6e6e6;
    display: flex;
    justify-content: space-between;
    align-items: center;
    span {
      display: block;
      height: 100%;
      font-size: 0.7rem;
      @include CC;
    }
    span.left {
      width: 50%;
      color: #666666;
    }
    span.right {
      width: 50%;
      color: white;
      background: linear-gradient(
        90deg,
        rgba(79, 147, 255, 1) 0%,
        rgba(58, 187, 253, 1) 100%
      );
    }
  }
}
</style>
